<template>
    <div class="main" >
        <div class="main-box">
            <!--顶部按钮 - start-->
            <div class="top-box">
                <div @click="hideLive()" class="top-button-box">
                    <div class="top-button"><i class="el-icon-s-platform"></i></div>
                    <div>编程</div>
                </div>
            </div>
            <!--顶部按钮 - end-->
            <!--进入直播间弹窗开始-->
            <div v-show="showEnter" class="enter-page-box">
                <div  @click="play" class="enter-page-button">立即进入直播间</div>
            </div>
            <!--进入直播间弹窗结束-->
            <div>
                <div class="prism-player" :class="showLive?'show-live':'hide-live'" id="player-con"></div>
            </div>
            <!--直播组件结束-->
            <!--消息提示开始-->
            <div v-if="showNotice" class="msg-box">
                <div class="msg-text">{{notice}}</div>
            </div>
            <!--消息提示结束-->
            <!--聊天界面开始-->
            <div class="chat-box">
                <div id="scroll_text" class="chat-list-main">
                    <div class="chat-list-box">
                        <div v-for="(item,key) in msgList" v-bind:key="key" class="chat-list-item">
                            <div v-if="item.type === 'sendToRoom'" class="chat-list-item-box">
                                <span class="chat-item-name">{{item.from_client_name}}：</span>
                                <span class="chat-item-text">{{item.content}}</span>
                            </div>
                            <div v-if="item.type === 'newUserEnter'" class="chat-list-item-box">
                                <span class="chat-item-system-name">系统：</span>
                                <span class="chat-item-system-text">{{item.name}}</span>
                                <span class="chat-item-system-name">进入直播间</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat-input-box">
                    <div class="chat-input">
                        <input type = "text" maxlength="100"  v-model = "msg" placeholder="请输入内容" />
                    </div>
                    <recording></recording>
                    <div class="chat-send-button" @click="sendInfo">
                        <span class="sounds-button-icon"><i class="el-icon-s-promotion"></i></span>
                        <span>发送</span>
                    </div>
                </div>
            </div>
            <!--聊天界面结束-->
            <!--功能按钮开始-->
            <div @click="sendIframeMessage()" class="function-box">
                <div class="clear-button">
                    <div class="function-icon"></div>
                    <div>旋转屏幕</div>
                </div>
            </div>
            <!--功能按钮结束-->
            <!--编程界面 - start-->
            <div class="code-main-box">
                <iframe :key="key" ref="iframe" src="/show/index.html" style="width: 100vw;height: 100vh;overflow-x:hidden;" frameborder="0"></iframe>
            </div>
            <!--编程界面 - end-->
        </div>
    </div>
</template>

<script>
    var Aliplayer = window.Aliplayer
    import Recording from './components/Recording.vue';
    // import { getUserId } from '@/api/live'
    // import Aliplayer from '@/components/aliplayer'
    // import Cookies from "js-cookie";
    export default {
        name: 'Show',
        components: {
            Recording
        },
        data() {
            return {
                player: {}, // 播放器对象
                ws:'', // webSocket连接
                source: '', // 直播地址
                liveData: '', // 直播数据
                showLive: true, // 是否展示直播
                userName: '',
                userId: '',
                liveId: '', // 直播id
                msg: '', // 发送消息内容
                msgList: [], // 消息列表
                nowNum: 0, // 在线人数
                notice: '', // 状态通知
                showNotice: false,
                timer: '', // 心跳定时器
                showEnter: false, // 是否展示进入直播间弹窗
                key: new Date().getTime(),
                iframeWin: {} // iframe 对象
            }},
        created() {
            let query=this.$route.query;
            if(!query.liveId) {
                this.$message({message: "获取直播信息失败！", type: "error",duration: 5000});
                return false;
            }
            this.liveId = query.liveId
            // this.getLiveData()
            this.initPlayer()
        },
        mounted:function(){
            this.iframeWin = this.$refs.iframe.contentWindow;
            window.addEventListener("message", this.handleMessage);
        },
        methods: {
            // 初始化播放器
            initPlayer(){
                let _this = this
                setTimeout(function(){
                    _this.player = new Aliplayer({
                            "id": "player-con",
                            "source": "artc://pull.nanshikeji.cn/50362830a1a9821e3b6416e120658659/1de835421257a0f35f75e5085d62bd5d?auth_key=1680062529-0-0-97f1bec635f4282579b09c61fbcff21b",
                            "width": "100%",
                            "height": "100vh",
                            "autoplay": true,
                            "isLive": true,
                            "rePlay": false,
                            "playsinline": true,
                            "preload": true,
                            "language": "zh-cn",
                            "controlBarVisibility": "hover",
                            "useH5Prism": true,
                            "skinLayout": [
                                {
                                    "name": "controlBar",
                                    "align": "blabs",
                                    "x": 0,
                                    "y": 0,
                                    "children": []
                                }
                            ]
                        }, function () {
                            // _this.play()
                            // 连接websocket
                            _this.connectWebSocket()
                            _this.showEnter = true
                            _this.eventInit()
                            console.log("The player is created");
                        }
                    );
                },1000);

            },
            // 监听播放器事件
            eventInit(){
                let _this = this
                _this.player.on('ready', () => {
                    console.log('ready')
                })
                _this.player.on('error', (player) => {
                    console.log(player)
                    _this.showNotice = true
                    _this.notice = '进入直播间失败请刷新重试'
                })
                _this.player.on('ended', () => {
                    console.log('ended')
                    _this.showNotice = true
                    _this.notice = '直播已结束'
                })
            },
            // 开始直播
            play(){
                this.showEnter = false
                this.showNotice = false
                this.player.play()
            },
            // 隐藏直播链接
            hideLive(){
                this.showLive = !this.showLive
            },
            // 获取直播列表
            getLiveData(){
                let _this = this
                this.showLive = true
                document.title = '秀码编程'
                _this.liveData = ''
                _this.source = 'artc://pull.nanshikeji.cn/test_1/test_1?auth_key=1677818061-0-0-920f6d1bd403c00af36200a277cf70d6'
                setTimeout(function(){
                    // _this.$refs.aliplayer.insertScriptTag()
                    _this.$refs.aliplayer.initAliplayer()
                },3000);


               /* const postData = {}
                postData.unionID = this.unionId
                postData.liveId = this.liveId
                postData.userid = this.openId
                this.showLive = true
                getLiveInfo(postData).then(response => {
                    if(response.code !== 200 && response.code !== 0){
                        if(response.message === '直播间已结束或者取消'){
                            this.showNotice = true
                            this.notice = response.message

                            return;
                        }
                        return;
                    }
                    document.title = response.data.theme
                    _this.liveData = response.data
                    _this.source = response.data.pullHlsUrl
                    // _this.source = response.data.pullRtmpUrl
                    _this.$refs.aliplayer.initAliplayer()
                    this.connectWebSocket()
                })*/
            },
            // 发送消息给 iframe
            sendIframeMessage() {
                // 在iframe页面中接收通过key也就是param接收，因此传输的数据可以是对象，包含多个key以及对应的数据
                this.iframeWin.postMessage({
                    type: 1,
                    data: 2
                }, "*");
            },
            // 接收 iframe
            handleMessage(event) {
                // 获取从iframe页面中传过来的值
                console.log(event)
            },
            // 连接websocket
            connectWebSocket() {
                this.ws = this.$globalWebSocket.ws
                const that =this
                this.ws.onopen = function() { // 绑定连接事件
                }
                this.ws.onmessage = function(evt) { // 绑定收到消息事件
                    that.showMsg(JSON.parse(evt.data))
                }
                this.ws.onclose = function() { // 绑定关闭或断开连接事件
                    console.log('Connection closed.')
                }
                // 分配房间
                this.bindRoom()
            },
            bindRoom(){
                console.log('bindRoom')
                let sendData = {}
                sendData.token = sessionStorage.getItem("token")
                sendData.type = 'bindRoom'
                sendData.liveId = this.liveId
                let postJson = JSON.stringify(sendData)
                this.ws.send(postJson)
            },
            // 处理接收到的数据
            showMsg(data){
                switch (data.type) {
                    case 'ping':
                        this.ws.send('{"type":"pong"}');
                        break;
                    case 11: // 更新直播间人数
                        this.nowNum = data.msg
                        break
                    case 'sendToRoom':
                        this.msgList.push(data)
                        break
                    case 13:
                        this.msgList.push(data)
                        break
                }
                this.toBottom()
            },
            // 发送
            sendInfo() {
                if(this.msg === '') {
                    return
                }
                let sendData = {}
                sendData.token = sessionStorage.getItem("token")
                sendData.type = 'sendToRoom'
                sendData.message_type = 1
                sendData.message_from = 1
                sendData.content = this.msg
                this.toBottom()
                let postJson = JSON.stringify(sendData)
                this.ws.send(postJson)
                // 置空聊天窗口
                this.msg = ''
            },
            // 控制滚动到底部
            toBottom(){
                this.$nextTick(() => {
                    const textarea = document.getElementById('scroll_text')
                    textarea.scrollTop = textarea.scrollHeight
                })
            },
            // 定时发送心跳
            initPing(){
                let _this = this
                // 获取随机数
                let timerNum = _this.randomNum(30000,60000)
                this.timer = setInterval( () => {
                    let sendData = {}
                    sendData.type = 1
                    let postJson = JSON.stringify(sendData)
                    _this.ws.send(postJson)
                }, timerNum)
            },
            // 获取随机数
            randomNum(minNum,maxNum){
                switch(arguments.length){
                    case 1:
                        return parseInt(Math.random()*minNum+1,10);
                    case 2:
                        return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10);
                    default:
                        return 0;
                }
            }
        }
    }
</script>

<style scoped>
    .top-box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: rgba(7, 7, 7, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1001;
        padding: 10px 0;
    }
    .top-button-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
    }
    .top-button{
        font-size: 30px;
        color: #ffffff;
    }
    #player-con{
        width: 100vw;
        height: 100vh;
    }
    .show-live{
        z-index: 1000;
    }
    .hide-live{
        z-index: -1;
    }
    .main{
        width: 100vw;
        min-height: 100vh;
        max-width: 100vw;
        position: relative;
        background-color: #000;
        overflow: hidden;
    }
    .main-box{
        width: 100vw;
        min-height: 100vh;
        max-width: 100vw;
        background-color: #000;
        overflow: hidden;
        z-index: 1;
    }
    .chat-box{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1001;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
    }
    .chat-input-box{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(7, 7, 7, 0.5);
        padding: 15px 0;
    }
    .chat-send-button{
        width: 80px;
        background-color: #05799a;
        color: #ffffff;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        border-radius: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sounds-button-icon{
        font-size: 18px;
        padding-right: 5px;
    }
    .chat-input{
        border: 1px solid #5DA3FA;
        border-radius: 30px;
        width: 300px;
        height: 30px;
        line-height: 30px;
        color: #ffffff;
        padding: 0 20px;
    }
    .chat-input input{
        width: 300px;
        background:none;
        outline:none;
        border:none;
        caret-color: #4292f6;
        color: #ffffff;
    }
    .chat-list-main{
        height: 100px;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .chat-list-box{
        width: 580px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 14px;
    }
    .chat-list-item{
        background-color: rgba(7, 7, 7, 0.5);
        display: flex;
        justify-content: flex-start;
        border-radius: 30px;
        margin: 0 10px 10px 10px;
        padding: 5px 10px;
        width: fit-content;
        max-width: 60vw;
    }
    .chat-list-item-box{
        overflow: hidden; /*超出的文本隐藏*/
        text-overflow: ellipsis; /*溢出用省略号显示*/
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 超出多少行*/
        -webkit-box-orient: vertical;
    }
    .chat-item-name{
        color: #43f647;
    }
    .chat-item-text{
        color: #ffffff;
    }
    .chat-item-system-name{
        color: #F7B500;
    }
    .chat-item-system-text{
        color: #5DA3FA;
    }
    .msg-box{
        position: absolute;
        top: 35%;
        left: 0;
        z-index: 10001;
    }
    .msg-text{
        width: 100vw;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        text-shadow:1px 1px 5px #faf8f9;
        letter-spacing: 3px;
    }
    .video{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    .enter-page-box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1002;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(7, 7, 7, 0.9);
    }
    .enter-page-button{
        color: #5DA3FA;
        border: 1px solid #ffffff;
        border-radius: 30px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 200px;
        font-size: 18px;
        z-index: 1003;
    }
    .function-box{
        position: absolute;
        top: 40vh;
        right: 20px;
        z-index: 10001;
        color: #ffffff;
    }
    .clear-button{
        font-size: 13px;
        width: 60px;
        color: #a3a1a1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
    }
    .function-icon{
        font-size: 16px;
        margin-bottom: 3px;
    }
    /*隐藏滚动条 Chrome Safari*/
    .chat-list-box::-webkit-scrollbar {
        display: none;
    }
    .chat-list-main{
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
    }
    .chat-list-main::-webkit-scrollbar {
        display: none;
    }
    .chat-list-main{
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
    }
    .code-main-box{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
</style>

<template>
    <div>
        <div
                class="sounds-send-button"
                @mousedown="startRecording"
                @mouseup="stopRecording"
                :class="recording?'sounds-send-start':'sounds-send-def'">
            <span class="sounds-button-icon"><i class="el-icon-microphone"></i></span>
            <span>{{recording?'松开发送':'按住说话'}}</span>
        </div>
    </div>
</template>

<script>
    import Recorder,{ ENCODE_TYPE } from 'recorderx';

    export default {
        data() {
            return {
                recording: false,
                recorder: null,
                audioData: null,
            };
        },
        created() {
            this.recorder = new Recorder({
                type: 'audio',
                sampleRate: 16000, // WAV 采样率
                bitRate: 16, // WAV 音频深度
                numOfChannels: 1,
            });
        },
        methods: {
            // 获取录音授权
            getAuth(){
                this.recorder.start().then(() => {
                    this.recorder.pause();
                    this.recorder.clear()
                }).catch(error => {
                    this.$message({message: "获取麦克风失败", type: "warning"});
                    console.log("Recording failed.", error);
                });
            },
            // 开始录音
            startRecording() {
                this.recording = true;
                this.recorder.start().then(() => {
                }).catch(error => {
                        this.$message({message: "获取麦克风失败", type: "warning",duration: 5000});
                        console.log("Recording failed.", error);
                });
                //可以用下面的代码来边录音边听
                // const audio = new Audio()
                // audio.autoplay = true
                // this.recorder.start().then(stream => {
                //   audio.srcObject = stream
                // })
                // .catch(error => alert(error));
            },
            // 结束录音
            async stopRecording() {
                this.recording = false;
                this.recorder.pause() // 先暂停录音
                this.audioData = this.recorder.getRecord({
                    encodeTo: ENCODE_TYPE.WAV,
                    compressible: true
                }) // 获取录音文件
                console.log('wav', this.audioData)
                // 将录音文件上传到服务器
                const formData = new FormData();
                formData.append('file', this.audioData, Date.parse(new Date()) + '.wav');
                /*const response = await fetch('/upload', {
                    method: 'POST',
                    body: formData,
                });*/
            },
            // 播放录音
            async playRecording() {
                const response = await fetch('/recording');
                const arrayBuffer = await response.arrayBuffer();
                const audioContext = new AudioContext();
                const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
                const source = audioContext.createBufferSource();
                source.buffer = audioBuffer;
                source.connect(audioContext.destination);
                source.start();
            },
        },
    };
</script>
<style scoped>
    .sounds-button-icon{
        font-size: 18px;
        padding-right: 5px;
    }
    .sounds-send-button{
        width: 100px;
        color: #ffffff;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        border-radius: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sounds-send-def{
        background-color: #97880d;
    }
    .sounds-send-start{
        background-color: #079702;
    }
</style>
